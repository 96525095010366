/**
 * Load image source
 */
export function loadImageSrc(callBack) {
	var images = document.getElementsByClassName('rode-img-loader');
	var imagesLength = images.length;
	for (var i = 0; i < imagesLength; i++) {
		let image = images[i];
		if(!image.classList.contains('loading')){
			image.classList.add('d-none');
			image.classList.add('loading');
			if(isImageExt(image)){
				let spinner = document.createElement('div');
				spinner.classList.add('rode-spinner');
				image.after(spinner);
				loadImage(image, function(){
					image.classList.remove('rode-img-loader');
					image.classList.remove('d-none');
					image.classList.remove('loading');
					spinner.remove();
				}, image);
			}
		}
	}
	callBack ? callBack() : null;
}

/**
 * Validate if the file path is a valid IMAGE file extension
 * @param DomElement el 
 * @returns boolean
 */
function isImageExt(el){
	var image_src = el.getAttribute('data-src');
	if(typeof image_src !== 'undefined'){
		image_src = image_src.toLowerCase();
		return (image_src.indexOf('.jpg') || image_src.indexOf('.png') || image_src.indexOf('.svg') || image_src.indexOf('.gif'));
	}else{
		return false;
	}
}

function loadImage(el, fn) {
	var img = new Image();
	var src = el.getAttribute('data-src');
	img.onload = function () {
		if (!!el.parent)
			el.parent.replaceChild(img, el)
		else
			el.src = src;
		fn ? fn() : null;
	}
	img.src = src;
}

window.loadImageSrc = loadImageSrc;