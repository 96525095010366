import { loadImageSrc } from './loadimageSrc.js';
const MEDIUM_SIZE_SCREEN = 768;
var players = [];

$(document).ready(() => {
  pauseVideoCarousel();

  // Will first replace every image data-src with src
  // And then execute the carousel resizing
  // loadImageSrc(carouselResizing);
  loadImageSrc();

  $(document).on('click', '.yt-thumb', function(e){
    let activeEmbedIndex = 0;
    for (let index = 0; index < players.length; index++) {
      const player = players[index];
      let playerID = $(player.h).attr('id');
      if(playerID === $(this).attr('data-id')){
          activeEmbedIndex = index;
      }
    }
    players[activeEmbedIndex].playVideo();
    $(this).hide();
  });

});

/**
 * Pause the video in the carousel if changes slide
 */
function pauseVideoCarousel() {
  $.getScript('https://www.youtube.com/iframe_api', (e) => {
    window.YT.ready(() => {
      var allVideoIframes = $('body main #page-container #page-content .carouselIndicatorsvideo iframe');
      var $carouselIndicatorsvideos = $('body main #page-container #page-content .carouselIndicatorsvideo');
      var carouselIds = $.map($carouselIndicatorsvideos, (div) => div.dataset.carouselId);

      var allVideoIframesLength = allVideoIframes.length;
      for (var i = 0; i < allVideoIframesLength; i++) {
        var player = new YT.Player(
          allVideoIframes[i].id,
          {
            events: {
              'onStateChange': onPlayerStateChange
            }
          }
        );
        players.push(player);
      }

      // If one video is playing
      // We stop the carousel to change slide
      function onPlayerStateChange(evt) {
        var carouselId = evt.target.getIframe().dataset.parentCarouselId; // Id of the carousel id
        // TODO ==> need to check if state is "1" but even if the video is playing | get the state "-1"
        if ([-1, 1].includes(evt.target.getPlayerState())) { 
          stopCarousel(carouselId);
          var otherVidoes = players.filter((p) => p.getIframe().id !== evt.target.getIframe().id);

          var otherVidoesLength = otherVidoes.length;
          for (var i = 0; i < otherVidoesLength; i++) {
            otherVidoes[i].pauseVideo();
          }
          var otherIds = carouselIds.filter((id) => carouselId !== id);
          startCarousel(otherIds);
        } else if (players.filter((p) => p.getPlayerState() === 1).length === 0) { // If no vidoe is playing start all carousel
          startCarousel(carouselIds);
        }
      }
      
      $carouselIndicatorsvideos.on('slide.bs.carousel', (e) => {
        var playersLength = players.length;
        for (var i = 0; i < playersLength; i++) {
          // Put pause only on the carousel where the video is
          // When we change slide
          // TODO ==> need to check if state is "1" but even if the video is playing | get the state "-1"
          if([-1, 1].includes(players[i].getPlayerState()) && $('#' + e.target.id).has('iframe#' + players[i].getIframe().id).get(0) ){ // check if the ifram id exist in the carousel
            players[i].pauseVideo();
          }
        }
      });
      
    });
  });
}

function stopCarousel(id) {
  var $carousel = $('body main #page-container #page-content #carouselIndicatorsvideo_' + id);
  $carousel.carousel('pause');
}

function startCarousel(ids) {
  var idsLength = ids.length;
  for (var i = 0; i < idsLength; i++) {
    var $carousel = $('body main #page-container #page-content #carouselIndicatorsvideo_' + ids[i]);
    $carousel.carousel('cycle');
  }
}
